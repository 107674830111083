import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './App.css';

import CrowForm from 'Crow/Form/Form.js';

// import CrowFieldCustomCrowFormBuilder from 'Crow/Form/Field/Custom/Crow/Form/Builder/Builder.js';

const crowFormHost = process.env.REACT_APP_CROW_FORM_HOST;

function CrowFormApp(props) {
	const {
		router
	} = props;

	useEffect(() => {
		// preload admin OTO/OTM field
		if (!window.CrowFormField) {
			window.CrowFormField = {};
		}

		window.React = React;
		window.ReactDOM = ReactDOM;
	}, []);

	let {
		name,
		m1,
		id
	} = router.params;

	if (!name) {
		return 'no_name';
	}

	if (!m1 || !id) {
		return 'no_data';
	}

	console.log("ADMIN PROPS:");
	console.log(props);

	return (
		(
			<>
				<CrowForm
					formHost={crowFormHost}
					name={name}
					m1={m1}
					id={Number(id)}
				/>
			</>
		)
	)
}

export default CrowFormApp;