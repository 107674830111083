import React from 'react';

const crowFormHost = process.env.REACT_APP_CROW_FORM_HOST;

function renderLabel(entity) {
	let label = (entity.attr || {}).label || entity.name;
	return label;
}

function renderEntity(props, entity) {
	const {
		form,
		getValue,
		setValue,
		getStatus,
		setStatus,
	} = props;

	if (typeof form.props.customEntity === 'function') {
		entity = form.props.customEntity(entity);
	}

	if (!entity.form) {
		entity.form = () => {
			return form;
		}
	}

	if (entity.children) {
		let children = entity.children.map((childEntity) => {
			childEntity.parent = () => {
				return entity;
			}

			return childEntity;
		});

		entity.children = children;
	}

	let reactKey = `${entity.type}_${entity.name}`;
	let entityProps = {
		key: reactKey,
		form,
		entity,
		getValue,
		setValue,
		getStatus,
		setStatus,
	}

	if (!entity.type) {
		// console.warn('SKIP:', entity);
		return '';
	}

	let entityType = entity.type.toLowerCase();
	let attr = entity.attr || {};

	let elm = (
		<CrowFormEntity {...entityProps} />
	);

	if (entityType === 'field') {
		if (!entityProps.entity.fieldType) {
			entityProps.entity.fieldType = 'text.small';
		}

		elm = (
			<CrowFormEntityField {...entityProps} />
		)
	}

	if (typeof form.props.renderEntity === 'function') {
		entityProps.elm = elm;

		return (
			<React.Fragment
				key={reactKey}
			>
				{form.props.renderEntity(entityProps)}
			</React.Fragment>
		);
	}

	let class_list = attr.class_list || [];
	let customClassList = '';
	class_list.forEach((item) => {
		customClassList += ` ${[item]}`;
	});

	if (entityType === 'form') {
		return (
			<div key={reactKey} className={`crow-form${customClassList}`}>
				{elm}
			</div>
		);
	}

	if (entityType === 'page') {
		return (
			<div key={reactKey} className={`crow-page${customClassList}`}>
				<h1>{renderLabel(entity)}</h1>
				{elm}
			</div>
		);
	}

	if (entityType === 'group') {
		return (
			<div key={reactKey} className={`crow-group${customClassList}`}>
				<h2>{renderLabel(entity)}</h2>
				{elm}
			</div>
		);
	}

	if (entityType === 'field') {
		return (
			<div key={reactKey} className={`crow-field${customClassList}`}>
				<label>{renderLabel(entity)}</label>
				{elm}
			</div>
		);
	}

	console.log('NO_TYPE:', entity);
	return (
		<div key={reactKey} className={`${entity.type}-entity`}>
			{elm}
		</div>
	);
}

function CrowFormEntity(props) {
	const {
		entity
	} = props;

	if (entity.skip) {
		return '';
	}

	if (entity.type !== 'FIELD') {
		if (entity.fieldType) {
			// WARN: DON'T MODIFY THE TREE_FORM!!!
			let entityClone = Object.assign({}, entity, {
				type: 'FIELD',
				orgType: entity.type,
			});

			return renderEntity(props, entityClone);
		}
	}

	if (entity.type !== 'FIELD') {
		return (
			<React.Fragment>
				{entity.children.map((childEntity) => {
					return renderEntity(props, childEntity);
				})}
			</React.Fragment>
		)
	}

	return renderEntity(props, entity);
}

function injectHead(fieldType, on = {}) {
	if (!window.CrowFormField) {
		window.CrowFormField = {};
	}

	let script = document.createElement('script');
	script.type = 'module';
	script.src = `${crowFormHost}/field/${fieldType}`; // ?update=1
	// script.src = `${crowFormHost}/field/${fieldType}?update=1`; // ?update=1

	script.onload = on.load;
	script.onerror = on.error;

	let scripts = document.scripts;
	for (let i = 0, l = scripts.length; i < l; i++) {
		if (scripts[i].src === script.src) {
			return;
		}
	}

	document.head.appendChild(script);
}

class CrowFormEntityField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fieldInjected: false,
			fieldReady: false,
		};
	}

	componentDidMount() {
		const {
			entity,
		} = this.props;

		// console.log('MOUNT:', entity.name);
		if (window.CrowFormField) {
			if (window.CrowFormField[entity.fieldType]) {
				this.setState({
					fieldReady: true,
				});

				return;
			}
		}

		this.injectHead(entity.fieldType, {
			load: (e) => {
				console.log('LOAD:', e.target.src);
			},

			error: (e) => {
				console.error(e);
			},
		});
	}

	injectHead(fieldType, on = {}) {
		if (!this.state.fieldInjected) {
			injectHead(fieldType, on);

			this.setState({
				fieldInjected: true,
			});
		}

		window.setTimeout(() => {
			if (!window.CrowFormField || !window.CrowFormField[fieldType]) {
				return this.injectHead(fieldType, on)
			}

			this.setState({
				fieldReady: true,
			});
		}, 1000);
	}

	getValue = () => {
		const {
			entity,
		} = this.props;

		if (entity.children) {
			let data = {};
			entity.children.forEach((childEntity) => {
				data[childEntity.name] = Object.assign({}, childEntity, {
					value: this.props.getValue(childEntity.name),
				});
			});

			return data;
		}

		return this.props.getValue(entity.name);
	}

	setValue = (value) => {
		const {
			entity,
		} = this.props;

		if (typeof value === 'object') {
			try {
				let encoded = btoa(JSON.stringify(value));
				value = `json:${encoded}`;
			} catch (err) { }
		}

		this.props.setValue(entity.name, value);
	}

	clearValue = () => {
		const {
			entity,
		} = this.props;

		if (entity.nullable) {
			this.props.setValue(entity.name, null);
		}
	}

	getStatus = () => {
		const {
			entity,
		} = this.props;

		return this.props.getStatus(entity.name);
	}

	setStatus = (status, ...msg) => {
		const {
			entity,
		} = this.props;

		return this.props.setStatus(entity.name, status, ...msg);
	}

	render() {
		const {
			form,
			entity,
		} = this.props;

		if (!entity.attr) {
			entity.attr = {};
		}

		if (!this.state.fieldReady) {
			return (
				<div>{entity.name} - {entity.fieldType}</div>
			)
		}

		let dynamicField = window.CrowFormField[entity.fieldType];
		if (!dynamicField) {
			return 'no_matching_field';
		}
		let Field = dynamicField || window.CrowFormField['text.small'];

		let fieldElm = (
			<Field
				key={`DYNAMIC_FIELD_${entity.name}`}
				classNameList={['dynamic-field']}

				form={form}
				field={entity}
				value={this.getValue()}
				setValue={this.setValue}
				setStatus={this.setStatus}
				getStatus={this.getStatus}
			/>
		);

		if (entity.nullable) {
			let nullClassName = "";
			if (this.getValue() === null) {
				nullClassName = "is-null";
			}

			return (
				<div className={`nullable-wrapper ${nullClassName}`}
					title="SHIFT+CLICK to set me to null"
					onClick={(e) => {
						if (e.shiftKey) {
							this.clearValue();
						}
					}}
				>
					{fieldElm}
				</div>
			)
		}

		return fieldElm;
	}
}

export default CrowFormEntity;