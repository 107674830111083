const {
  handle200Res,
  handleNon200Res,
  handleErr,
} = require('./handlers');

const req = function (uri, opts, on = {}, thisArg = false, _kali = false) {
  if (!thisArg) {
    thisArg = this;
  }

  let timeout = false, _timer;
  if (opts.timeout) {
    let duration = opts.timeout;
    _timer = setTimeout(() => {
      timeout = true;

      _res = { status: 'kali::timeout' };
      data = false;

      handleNon200Res.call(thisArg, _kali, _res, on);
    }, duration);
    console.log('timeout:', duration);
  }

  let _res;
  return fetch(uri, opts)
    .then((res) => {
      if (!timeout) {
        console.debug('kali::timout _timer cleared...');
        clearTimeout(_timer);
      } else {
        throw new Error("kali::timeout");
      }

      if (!res.ok) {
        res.json().finally(handleNon200Res.bind(thisArg, _kali, res, on));

        // stop the promise chain!
        return false;
      }

      _res = res;
      return res.json();
    })
    .then((data) => {
      if (!data) {
        return;
      }

      handle200Res.call(thisArg, _kali, _res, data, on);
    })
    .catch((err) => {
      if (err.message === "kali::timeout") {
        return;
      }

      handleErr.call(thisArg, _kali, err, on);
    });
};

const reqSync = async function (uri, opts) {
  if (opts.timeout) {
    return console.error('opts.timeout sync calls are not supported by kali');
  }

  let res = await fetch(uri, opts);

  let data;
  try {
    data = await res.json();
  } catch (err) {
    return { res, data, err };
  }

  return { res, data, err: false };
}

const usage = function () {
  console.log(`
async:
new kali({opts...}).{get,post}(uri, {on...}, (thisArg=false));
new kali({opts...}).{get,post}Sync(uri, {on...}, (thisArg=false));

sync:
let { res, data, err } = await new kali({opts...}).{get,post}(uri);
let { res, data, err } = await new kali({opts...}).{get,post}Sync(uri);
`);
}

const kali = function (opts = {}) {
  if (typeof opts !== 'object') {
    return console.error(usage());
  }

  opts.method = 'GET';
  if (opts.body || opts.form) {
    opts.method = 'POST';
  }

  if (opts.method === 'POST') {
    if (typeof opts.headers !== 'object') {
      opts.headers = new Headers();
    }

    if (!(opts.headers instanceof Headers)) {
      console.debug('opts.headers converted to new Headers()');
      opts.headers = new Headers(opts.headers);
    }

    if (opts.body && typeof opts.body === 'object') {
      try {
        opts.body = JSON.stringify(opts.body);
        if (!opts.headers.has('content-type')) {
          opts.headers.set('content-type', 'application/json');
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    }

    if (opts.form) {
      if (opts.form instanceof FormData) {
        opts.body = opts.form;
      }

      // TODO: convert to JSON 
      // TODO :and skip OPTIONS?
    }
  }

  if (opts.auth) {
    // TODO: auth
  }

  opts.query = opts.query || {};
  if (opts.query.XYZ) {
    // TODO: cols/delete/etc...
  }

  if (opts.headers) {
    console.log('headers:', opts.headers);
  }
  // console.log(opts);

  this.get = (uri, on = {}, thisArg = false) => {
    this._uri = uri;
    this._opts = opts;
    this._on = on;
    this._thisArg = thisArg;

    return req(uri, opts, on, thisArg, this);
  };

  this.getSync = (uri) => {
    return reqSync(uri, opts);
  };

  this.post = (uri, on = {}, thisArg = false) => {
    this._uri = uri;
    this._opts = opts;
    this._on = on;
    this._thisArg = thisArg;

    if (opts.method !== 'POST') {
      opts.method = 'POST';
    }

    return req(uri, opts, on, thisArg, this);
  };

  this.postSync = (uri) => {
    if (opts.method !== 'POST') {
      opts.method = 'POST';
    }

    return reqSync(uri, opts);
  };

  this.retry = (duration = 1000) => {
    if (duration < 1000) {
      console.warn('retry duration(ms) must be at least 1000');
      return;
    }

    setTimeout(() => {
      return this[this._opts.method.toLowerCase()](
        this._uri,
        this._on,
        this._thisArg,
      );
    }, duration);
  }

  this.create = (uri, on = {}, thisArg = false) => {
    return this.post(uri, Object.assign({}, opts, {
      mode: 'CREATE',
    }), on, thisArg);
  };

  this.update = (uri, on = {}, thisArg = false) => {
    return this.post(uri, Object.assign({}, opts, {
      mode: 'UPDATE',
    }), on, thisArg);
  };

  this.delete = (uri, on = {}, thisArg = false) => {
    uri += '&delete=1';
    uri = uri.replace('?&', '?');

    return this.post(uri, Object.assign({}, opts, {
      mode: 'DELETE',
    }), on, thisArg);
  };
}

// convert obj from FormData{} to JSON{}
const formDataToJSONObj = function (fd) {
  let obj = {};
  for (let d of fd.entries()) {
    console.debug(d[0], d[1]);
    obj[d[0]] = d[1];

    if (d[0] === 'id' || ~d[0].indexOf('.id')) {
      obj[d[0]] = Number(d[1]);
    }
  }
  console.debug(obj);
  return obj;
}

module.exports = kali;
