const hasKeyType = function (obj, key, type) {
  if (!obj[key]) {
    console.warn('missing callback:', key);
    return false;
  }

  let t = (typeof obj[key]);
  if (t !== type) {
    console.warn('callback is invalid type:', t);
    console.warn('callback should be valid type:', type);
    return false;
  }

  return true;
}

// --

const defaultHandler = function (_kali, res, data) {
  if (!res) {
    return failure(res);
  }

  if (res.status === 200) {
    console.info(`statusCode: ${res.status}`);
  } else {
    console.warn(`statusCode: ${res.status}`);
  }

  console.info(`statusText: ${res.statusText}`);
  console.info('data:', data);
}

const success = function (_kali, res, data) {
  return defaultHandler(_kali, res, data);
}

const failure = function (err) {
  console.error(err);
}

const notAuthorized = function (_kali, res, data) {
  return defaultHandler(_kali, res, data);
}

const notFound = function (_kali, res, data) {
  return defaultHandler(_kali, res, data);
}

const serverError = function (_kali, res, data) {
  return defaultHandler(_kali, res, data);
}

const serverNotAvailable = function (_kali, res, data) {
  return defaultHandler(_kali, res, data);
}

const timeout = function(_kali, res, data) {
  return defaultHandler(_kali, res, data);
}

const handlers = {
  success,
  failure,

  notAuthorized,
  notFound,
  serverError,
  serverNotAvailable,

  timeout,
};

// --

const handle200Res = function (_kali, res, data, on = {}) {
  if (!hasKeyType(on, 'success', 'function')) {
    on.success = handlers.success;
  }

  return on.success.call(this, _kali, res, data);
}

const handleNon200Res = function (_kali, res, on = {}, data) {
  try {
    if (data) {
      console.log('data:', data);
    }

    let key;
    switch (res.status) {
      case 401:
        key = 'notAuthorized';
        break;

      case 404:
        key = 'notFound';
        break;

      case 500:
        key = 'serverError';
        break;

      case 503:
        key = 'serverNotAvailable';
        break;

      case 'kali::timeout':
        key = 'timeout';
        break;

      default:
        console.warn('no callback for statusCode:', res.status);
    }

    if (!hasKeyType(on, key, 'function')) {
      if (hasKeyType(handlers, key, 'function')) {
        if (!hasKeyType(on, 'failure', 'function')) {
          on.failure = handlers.failure;
        }
        return on.failure.call(this, _kali, res, 'no callback for statusCode: ' + res.status);
      }
      on[key] = handlers[key];
    }

    return on[key].call(this, _kali, res, data);
  } catch (err) {
    handleErr(_kali, err, on);
  }
}

const handleErr = function (_kali, err, on = {}) {
  if (!hasKeyType(on, 'failure', 'function')) {
    on.failure = handlers.failure;

    console.error('FATAL: on.failure should be defined!');
    console.error(err);
  }

  return on.failure.call(this, _kali, err);
}

module.exports = {
  handle200Res,
  handleNon200Res,
  handleErr,
};