import React from 'react';
import CrowFormEntity from 'Crow/Form/Entity.js';

import kali from 'kali';

class CrowForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				flatForm: this.props.form,
				treeForm: {}
			},
			formName: this.props.formName,

			modValues: {},
			orgValues: {},
			newValues: {},
			delValues: {},

			entityStatus: {}
		};
	}

	init() {
		let {
			name,
			form,
			execHost,
		} = this.props;

		if (name) {
			return this.fetchFormData();
		}

		if (execHost && form) {
			return this.fetchFormData();
		}
	}

	componentDidMount() {
		// webpack config externals
		if (!window.React) {
			window.React = React;
		}

		this.init();
	}

	componentDidUpdate(prevProps) {
		if (this.state.formName) {
			if (prevProps.m1 !== this.props.m1) {
				// debugger;
				return this.fetchFormData();
			}

			if (prevProps.id !== this.props.id) {
				// debugger;
				return this.fetchFormData();
			}

			return;
		}

		if (JSON.stringify(prevProps.form) !== JSON.stringify(this.props.form)) {
			this.setState({
				form: {
					flatForm: this.props.form || {},
					treeForm: {},
				}
			});

			// debugger;
			return this.fetchFormData();
		}
	}

	req(action, method, uri, opts = {}) {
		console.log('ACTION:', action);
		console.log('URI:', uri, method);

		new kali(opts)[method](uri, {
			success: (_kali, res, crowRes) => {
				if (action === 'FETCH_FORM_DATA') {
					let flatForm = {};
					try {
						flatForm = JSON.parse(atob(crowRes.form));
					} catch (err) {
						debugger;
					}
					// console.log(JSON.stringify(flatForm, null, 2));

					this.setState({
						form: {
							...this.state.form,
							flatForm,
						}
					});

					let data = crowRes.data;
					this.updateModelData(data);
				}

				if (action === 'POST_FORM_DELETE') {
					console.log(crowRes);
					return window.location.reload();

					if (typeof this.props.onDelete === 'function') {
						this.props.onDelete(crowRes);
					}

					// TODO: replace values with response from server
					// TODO: instead of what we are sending, this will
					// TODO: work fine for now though...
					this.setState({
						delValues: {},
					});
				}

				if (action === 'POST_FORM_DATA') {
					console.log(crowRes);

					if (typeof this.props.onSave === 'function') {
						this.props.onSave(crowRes);
					}

					// TODO: replace values with response from server
					// TODO: instead of what we are sending, this will
					// TODO: work fine for now though...
					this.setState({
						orgValues: {
							...this.state.orgValues,
							...this.state.newValues,
						},
						newValues: {},
						modValues: {},
					});
				}
			},

			failure: (_kali, err) => {
				console.log(_kali);
				console.log(err);

				debugger;
			}
		}, true);
	}

	fetchFormData() {
		let body = {
			m1: (this.props.form || {}).m1 || this.props.m1,
			id: (this.props.form || {}).id || this.props.id,
			name: this.props.name,
		};
		console.log('BODY:', body);

		if (typeof this.props.form === 'object') {
			if (Object.keys(this.props.form).length > 0) {
				let form = btoa(JSON.stringify(this.props.form));
				body.form = `json:${form}`;
				body.exec = this.props.execHost;
			}
		}

		let uri = `${this.props.formHost}/form`;
		this.req('FETCH_FORM_DATA', 'post', uri, {
			body,
		});
	}

	updateModelData(modelData = {}) {
		console.log('updateModelData');

		let {
			flatForm
		} = this.state.form;

		// console.log(JSON.stringify(flatForm, null, 2));
		let treeForm = this.buildTree(flatForm);

		this.setState({
			form: {
				...this.state.form,
				treeForm,
			},

			orgValues: Object.assign({}, modelData),
			// newValues: Object.assign({}, modelData),
		});
	}

	getEntity(flatForm, entityKey) {
		let entities = {};
		Object.keys(flatForm).forEach((entityType) => {
			if (entityType === 'relation_data') {
				return;
			}

			for (let [key, val] of Object.entries(flatForm[entityType])) {
				entities[key] = val;
				entities[key].type = entityType;
			}
		});

		if (!entities[entityKey]) {
			debugger;
		}

		let entity = {
			'type': entities[entityKey].type,
			'flat': entities[entityKey],
		};
		if (Array.isArray(entity.flat)) {
			entity.flat = entity.flat.map((entity) => {
				// ["entityName", {entity.attr...}]
				if (Array.isArray(entity)) {
					return entity[0];
				}

				// "entityName"
				return entity;
			});
		}
		delete (entity.flat.type);

		if (!entity.flat) {
			return false;
		}

		for (let [key, val] of Object.entries(entities)) {
			for (let v of Object.values(val)) {
				// ["entityName", {entity.attr...}]
				if (Array.isArray(v)) {
					if (v[0] === entityKey) {
						entity.attr = v[1];
					}
				}
			}
		}

		return entity;
	}

	getFields(entity, fields = []) {
		(entity.children || []).forEach((childEntity) => {
			if (childEntity.type !== 'FIELD') {
				let childFields = this.getFields(childEntity);
				fields = fields.concat(childFields);
				return fields;
			}

			fields.push(childEntity);
		});

		return fields;
	}

	_buildTree(form, entityKeys) {
		let tree = [];

		entityKeys.forEach((entityKey) => {
			if (Array.isArray(entityKey)) {
				entityKey = entityKey[0];
			}

			let formEntity = this.getEntity(form, entityKey);
			if (!formEntity) {
				console.log('MISSING:', entityType, entityKey);
				return;
			}
			let entityType = formEntity.type;

			let entity = {
				type: entityType.substr(0, entityType.length - 1).toUpperCase(),
				name: entityKey,
				attr: formEntity.attr || {},
				children: false,
			};

			if (entity.attr.render === false) {
				return false;
			}

			if (entity.type === 'FIELD') {
				entity = Object.assign({}, entity, formEntity.flat);
			}

			if (entity.type !== 'FIELD') {
				if (Array.isArray(formEntity.flat)) {
					entity.children = this._buildTree(form, formEntity.flat);

					if (entity.attr.fieldType) {
						entity.children = this.getFields(entity).map((field) => {
							return field;
						});
					}
				}
			}

			tree.push(entity);
		});

		return tree;
	}

	buildTree(flatForm) {
		let formKey = Object.keys(flatForm.forms)[0];

		let entity = {
			type: 'FORM',
			name: formKey,
			children: this._buildTree(flatForm, flatForm.forms[formKey]),
		};

		return entity;
	}

	getValue(name) {
		let {
			modValues,
			orgValues,
			newValues,
		} = this.state;

		if (typeof newValues[name] === 'undefined') {
			if (!modValues[name]) {
				return orgValues[name];
			}
		}

		return newValues[name];
	}

	setValue(name, value, force = false) {
		let {
			modValues,
			orgValues,
			newValues,
			delValues,
		} = this.state;

		if (value === 'CROW::DELETE') {
			this.setState({
				delValues: {
					...delValues,
					[name]: true,
				}
			});

			return;
		}

		modValues[name] = true;
		newValues[name] = value;

		console.log(name);
		console.log('ORG:', orgValues[name]);
		console.log('NEW:', newValues[name]);

		if (!force && orgValues[name] === newValues[name]) {
			delete (modValues[name]);
			delete (newValues[name]);
		}

		this.setState({
			modValues,
			newValues,
		});
		// console.log(this.state.orgValues);
		// console.log(this.state.newValues);

		if (typeof this.props.onSetValue === 'function') {
			this.props.onSetValue.call(this, name, value, newValues);
		}
	}

	getStatus(name) {
		return this.state.entityStatus[name] || {};
	}

	setStatus(name, status, ...msg) {
		let { entityStatus } = this.state;
		entityStatus[name] = {
			"status": status,
			"msg": msg,
		};

		this.setState({
			entityStatus,
		});
		// console.log(this.state.entityStatus);
	}

	postFormData(e) {
		if (Object.keys(this.state.newValues || {}).length === 0) {
			return;
		}

		let data = {};
		Object.entries(this.state.newValues).forEach((entry) => {
			let [key, val] = entry;

			if (typeof val === 'string') {
				// catch JSON
				try {
					let d = JSON.parse(val);
					if (typeof d === 'object') {
						let encoded = btoa(JSON.stringify(val));
						val = `json:${encoded}`;
					}
				} catch (err) { }
			}

			data[key] = val;
			if (~key.indexOf('.id')) {
				data[key] = Number(val);
			}
		});

		// TODO: check for required entities...

		let body = {
			m1: (this.props.form || {}).m1 || this.props.m1,
			id: (this.props.form || {}).id || this.props.id,
			name: this.props.name,
			data,
		};

		if (typeof this.props.form === 'object') {
			if (Object.keys(this.props.form).length > 0) {
				let form = btoa(JSON.stringify(this.props.form));
				body.form = `json:${form}`;
				body.exec = this.props.execHost;
			}
		}

		console.log(body);
		// return;

		let uri = `${this.props.formHost}/form/data`;
		this.req('POST_FORM_DATA', 'post', uri, {
			body,
		});
	}

	postFormDelete(e) {
		let body = {
			m1: (this.props.form || {}).m1 || this.props.m1,
			id: (this.props.form || {}).id || this.props.id,
			data: this.state.delValues,
		};

		if (typeof this.props.form === 'object') {
			if (Object.keys(this.props.form).length > 0) {
				let form = btoa(JSON.stringify(this.props.form));
				body.form = `json:${form}`;
				body.exec = this.props.execHost;
			}
		}

		let uri = `${this.props.formHost}/form/data?delete=1`;
		this.req('POST_FORM_DELETE', 'post', uri, {
			body,
		});
	}

	render() {
		const {
			flatForm,
			treeForm,
		} = this.state.form;

		let m1 = (this.props.form || {}).m1 || this.props.m1;
		if (!m1) {
			return 'no_data';
		}

		if (!flatForm || !flatForm.forms) {
			return 'no_flat_form';
		}

		// TODO: add loop for multiple forms
		let treeFormKeys = Object.keys(treeForm);
		if (treeFormKeys.length === 0) {
			return 'no_tree_form';
		}

		return (
			<div>
				<CrowFormEntity
					form={this}
					entity={{
						type: 'ROOT',
						name: 'root.1',
						children: [treeForm],
					}}
					getValue={this.getValue.bind(this)}
					setValue={this.setValue.bind(this)}
					getStatus={this.getStatus.bind(this)}
					setStatus={this.setStatus.bind(this)}
				/>

				<div className='crow-form-submit-btn-container'>
					<button
						className='crow-form-submit-btn'
						onClick={this.postFormData.bind(this)}
					>Create/Update</button>
					{Object.keys(this.state.delValues).length > 0 &&
						< button
							className='crow-form-submit-btn'
							onClick={this.postFormDelete.bind(this)}
						>Execute "{Object.keys(this.state.delValues).length}" Pending Deletion(s)</button>
					}

					<p>
						<strong>Pending Updates:</strong>
					</p>
					<pre>
						<code>{JSON.stringify(this.state.newValues, null, 2)}</code>
					</pre>
					<p>
						<strong>Pending Deletions:</strong>
					</p>
					<pre>
						<code>{JSON.stringify(this.state.delValues, null, 2)}</code>
					</pre>
				</div>
			</div>
		);
	}
}

export default CrowForm;